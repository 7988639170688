import { globalEnvironment } from "./globalEnviroments";

const API_URL = "https://atsdev03bis.actiontracker.eu";
const URL_WS = 'wss://atsdev03bis.actiontracker.eu/api-ota/';
const GEOSERVER_BASES= 'https://atsdev.actiontracker.es:28080/geoserver/';
const GEOSERVER_WORKSPACE_1 = 'ActionTracker/ows?';
const GEOSERVER_OVERLAY= 'https://atsdev.actiontracker.es:28080/geoserver/';
const URL_MQTT = 'wss://atsdev03bis.actiontracker.eu:443/mqtt/';
const API_GEO = 'https://atsdev03bis.actiontracker.eu/api-geo/'
const URL_TRAMAS = API_URL + ":4000/api-tramas/";
const API_STATISTIC = 'https://atsdev03bis.actiontracker.eu';
const INTERNAL_PASSWORD = 'd1229bd8-ff34-4e52-beda-d5123c8fda2f';

/**
 * Dev
 */
export const environment = {
  environment: "atsdev03bis",
  production: false,
  name: "atsdev03bis",
  host: globalEnvironment,
  api_url: `${API_URL}`,
  API_URL: `${API_URL}/`,
  MQTT_URL: `${URL_MQTT}`,
  SOCKET_URL: `${API_URL}/api`,
  API_TRAMA_URL: `${API_URL}/api`,
  API_SERVER: `${API_URL}/api-ats`,
  API_AUTENTICACION_URL: `${API_URL}/api/security/oauth/`,
  API_TILE: `${API_URL}/api-process-tile`,
  WS_URL : `${URL_WS}`,
  GEOSERVER_WFS_URL: `${GEOSERVER_OVERLAY}${GEOSERVER_WORKSPACE_1}`,
  GEOSERVER_WMS_URL: `${GEOSERVER_BASES}`,
  API_GEO: API_GEO,
  IVTRAMAS: `${API_URL}/ivtramas/api-tramas/api/`,
  API_TRAMAS: `${URL_TRAMAS}api/trama/userQuery/`,
  API_STATISTIC: `${API_STATISTIC}/api-stat/`,
  API_TELTONIKA: `${API_URL}/api-teltonika/`,
  API_NOTIFICATION: `${API_URL}/api-notification`,
  INTERNAL_PASSWORD: INTERNAL_PASSWORD
};
